<template>
  <div id="about">
    <b-container class="mt-3">
        <h2>关于</h2>
        <h3>项目地址</h3>
        <p><a href="https://github.com/rwv/chinese-dos-games" target="_blank" rel="noopener">https://github.com/rwv/chinese-dos-games</a>
        </p>
        <h3>感谢</h3>
        <p>
        <ul>
            <li><a href="https://github.com/dreamlayers/em-dosbox" target="_blank" rel="noopener">dreamlayers/em-dosbox: An Emscripten
                port of DOSBox</a></li>
            <li><a href="https://github.com/db48x/emularity" target="_blank" rel="noopener">db48x/emularity: easily embed emulators</a></li>
            <li><a href="https://tieba.baidu.com/p/3962261741" target="_blank" rel="noopener">衡兰若芷制作的DOS游戏合集</a></li>
            <li><a href="https://www.flaticon.com/free-icon/gamepad_214304" target="_blank" rel="noopener">Favicon made by Pixel Buddha from flaticon</a></li>
            <li><a href="https://github.com/caiiiycuk/js-dos" target="_blank" rel="noopener">caiiiycuk/js-dos: The best API for running dos programs in browser</a></li>
        </ul>
        </p>
        <h3>许可</h3>
        <p><a href="https://www.gnu.org/licenses/gpl-3.0.html" target="_blank" rel="noopener">The GNU General Public License v3.0</a></p>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "about",
  metaInfo: {
    title: "关于",
    meta: [
      {
        name: "description",
        content: "在线 DOS 游戏的一些事，项目地址、游戏列表、感谢与许可。",
      },
      { name: "keywords", content: "在线, DOS, 游戏, 在线DOS游戏" },
      { property: "og:title", content: "在线 DOS 游戏的一些事" },
      { property: "og:url", content: "https://dos.zczc.cz/about" },
      {
        property: "og:description",
        content: "在线 DOS 游戏的一些事，项目地址、游戏列表、感谢与许可。",
      },
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/about" }],
  },
};
</script>