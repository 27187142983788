<template>
  <div id="wikiSave">
    <b-container class="mt-3">
      <h1 class="mb-3">游戏引擎对比</h1>
      <b-card-group deck>
        <b-card>
          <b-card-title>
            js-dos
            <small class="ml-1">
              <b-badge variant="success">推荐</b-badge>
            </small>
          </b-card-title>
          <b-card-text>
            <h4>优点</h4>
            <ul>
              <li>使用 WebAssembly，性能更高</li>
              <li>支持直接修改存档文件</li>
              <li>游戏兼容性更好</li>
              <li>浏览器兼容性更好</li>
            </ul>
            <h4>缺点</h4>
            <ul>
              <li>需手动保存进度</li>
            </ul>
            <h4>项目地址</h4>
            <a
              href="https://github.com/caiiiycuk/js-dos"
              target="_blank"
              rel="noopener"
              >https://github.com/caiiiycuk/js-dos</a
            >
          </b-card-text>
        </b-card>
        <b-card title="em-dosbox">
          <b-card-text>
            <h4>优点</h4>
            <ul>
              <li>使用 asm.js，兼容特别老的浏览器</li>
              <li>可以自动保存进度</li>
            </ul>
            <h4>缺点</h4>
            <ul>
              <li>兼容性较差</li>
              <li>不支持修改存档文件</li>
            </ul>
            <h4>项目地址</h4>
            <a
              href="https://github.com/dreamlayers/em-dosbox"
              target="_blank"
              rel="noopener"
              >https://github.com/dreamlayers/em-dosbox</a
            >
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-container>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";

export default {
  name: "wikiSave",
  components: {
    BBadge,
  },
  metaInfo: {
    title: "游戏引擎对比",
    meta: [
      {
        name: "description",
        content: "在线 DOS 游戏的游戏引擎对比。",
      },
      { name: "keywords", content: "在线, DOS, 游戏, 在线DOS游戏" },
      { property: "og:title", content: "在线 DOS 游戏的游戏引擎对比" },
      { property: "og:url", content: "https://dos.zczc.cz/wiki/save" },
      {
        property: "og:description",
        content: "在线 DOS 游戏的游戏引擎对比。",
      },
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/wiki/save" }],
  },
};
</script>