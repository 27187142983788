<template>
  <div id="error">
    <ErrorComponent :error_code="error_code" :error_message="error_message" />
  </div>
</template>

<script>
import ErrorComponent from "@/components/ErrorComponent";

export default {
  name: "error",
  metaInfo: {
    title: "出错了！",
    meta: [
      { name: "description", content: "出错了！" },
      { property: "og:title", content: "出错了！ - 在线 DOS 游戏" },
      { property: "og:description", content: "出错了！" },
      { name: "robots", content: "noindex" },
      { name: "prerender-status-code", content: "404" }
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/error" }],
  },
  computed: {
    error_code: () => "404",
    error_message: () => "出错了！试试其他页面？",
  },
  components: {
    ErrorComponent,
  },
};
</script>